import React, { useRef } from 'react';
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import Layout from "../Layout"
import FullscreenHeader from "../components/Content/FullscreenHeader";
import CapabilitiesListSection from '../components/Content/CapabilitiesListSection';
import ToolsContent from "../components/Rates/Tools";
import booked from "../images/og-booked.png";


const Capabilities = ({ data, location }) => {
    const title = `User Experience and Creative Technology rates.`;
    const description = `Over twenty years in user experience, brand strategy, and technology at work for you.`
    const available = useRef(true)
    const ogImage = `https://michaellisboa.com${booked}`;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
                <meta name="image" content={ogImage} />
                <meta property="og:image" content={ogImage} />
                <meta name="twitter:image" content={ogImage} />
            </Helmet>
            {available.current ?
                <>
                    <FullscreenHeader
                        media={data.hero_img}
                        content={'<h1 class="h1__xlarge">Worth every<br />damn penny.</h1><p class="uk-text-lead">Twenty-five years of <span class="uk-text-nowrap">brand experience</span> and<br />creative technology <span class="uk-text-nowrap">working for you.</span></p>'}
                    />

                    <CapabilitiesListSection />

                    <ToolsContent />
                </>
                :
                <div className="about__header uk-background-gun uk-light uk-flex uk-flex-middle">
                    <div className="uk-container uk-container-expand">
                        <div className="uk-width-1-1 uk-width-4-5@m">
                            <div className="uk-padding-small uk-width-1-1 uk-width-auto@s">
                                <h1 className="text-hero uk-margin-bottom">(429) Too many requests.</h1>
                                <p className="uk-text-lead uk-margin-remove-vertical">
                                    I'm fully booked and won't be taking on new clients through April 2022.
                                </p>
                                <p className="uk-text-lead">
                                    Do you have a really cool project coming up? <a href="#contact-form" className="uk-text-nowrap" data-uk-scroll>
                                        Let's catch up</a> to talk about scheduling for this Spring.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    )
}
export default Capabilities;

export const capabilitiesQuery = graphql`
    fragment capabilitiesQuery on File {
        childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    query capabilitiesQuery {
        hero_img: file(relativePath: { eq: "mypic_rates.jpg" }) {
            ...ratesPhoto
          }
    }`